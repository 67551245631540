import React, { useState } from 'react';
import QRCode from 'qrcode';
import './QRCodeGenerator.css';
import {ReactComponent as NewIcon} from '../assets/new-star.svg';

const QRCodeGenerator = () => {
  const [inputType, setInputType] = useState('url');
  const [inputValue, setInputValue] = useState('');
  const [fileType, setFileType] = useState('png');
  const [size, setSize] = useState(500);
  const [qrCode, setQrCode] = useState('');
  const [logo, setLogo] = useState(null);

  const MAX_QR_CODES = 15; // Reduced limit
  const TIME_FRAME = 30 * 60 * 1000; // 30 minutes in milliseconds

  const generateQRCode = async () => {
    const now = Date.now();
    const qrData = JSON.parse(localStorage.getItem('qrData')) || { count: 0, timestamp: now };

    if (now - qrData.timestamp > TIME_FRAME) {
      qrData.count = 0;
      qrData.timestamp = now;
    }

    if (qrData.count >= MAX_QR_CODES) {
      alert('You have reached the maximum number of QR codes generated per 30 minutes. Please try again later.');
      return;
    }

    try {
      let data = inputValue;
      if (inputType === 'url') {
        if (!/^https?:\/\//i.test(data)) {
          data = `https://${data}`;
        }
      } else if (inputType === 'wifi') {
        data = `WIFI:T:WPA;S:${inputValue.ssid};P:${inputValue.password};;`;
      } else if (inputType === 'tweet') {
        data = `https://twitter.com/intent/tweet?text=${encodeURIComponent(inputValue)}`;
      } else if (inputType === 'sms') {
        data = `sms:${inputValue.number}?body=${encodeURIComponent(inputValue.message)}`;
      } else if (inputType === 'tel') {
        data = `tel:${inputValue}`;
      } else if (inputType === 'bitcoin') {
        data = `bitcoin:${inputValue}`;
      } else if (inputType === 'monero') {
        data = `monero:${inputValue}`;
      }

      const options = {
        errorCorrectionLevel: 'H',
        type: fileType,
        quality: 0.95,
        margin: 1,
        color: {
          dark: '#000000',
          light: '#FFFFFF',
        },
      };

      const generateQRWithLogo = async (qrDataUrl, logoSrc, canvasSize) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const qrImage = await loadImage(qrDataUrl);
        const logoImage = await loadImage(logoSrc);

        canvas.width = canvasSize;
        canvas.height = canvasSize;
        ctx.drawImage(qrImage, 0, 0, canvasSize, canvasSize);

        const logoSize = canvasSize * 0.2;
        const logoX = (canvasSize - logoSize) / 2;
        const logoY = (canvasSize - logoSize) / 2;

        const aspectRatio = logoImage.width / logoImage.height;
        let logoWidth = logoSize;
        let logoHeight = logoSize;

        if (aspectRatio > 1) {
          logoHeight = logoWidth / aspectRatio;
        } else {
          logoWidth = logoHeight * aspectRatio;
        }

        const adjustedLogoX = logoX + (logoSize - logoWidth) / 2;
        const adjustedLogoY = logoY + (logoSize - logoHeight) / 2;

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(logoX, logoY, logoSize, logoSize);
        ctx.drawImage(logoImage, adjustedLogoX, adjustedLogoY, logoWidth, logoHeight);

        return canvas.toDataURL();
      };

      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = src;
        });
      };

      // Generate preview QR code (fixed size)
      const previewOptions = { ...options, width: 300 };
      const previewQrDataUrl = await QRCode.toDataURL(data, previewOptions);

      // Generate full-size QR code for download
      const fullSizeOptions = { ...options, width: size };
      const fullSizeQrDataUrl = await QRCode.toDataURL(data, fullSizeOptions);

      if (logo) {
        const previewWithLogo = await generateQRWithLogo(previewQrDataUrl, logo, 300);
        const fullSizeWithLogo = await generateQRWithLogo(fullSizeQrDataUrl, logo, size);
        setQrCode({ preview: previewWithLogo, fullSize: fullSizeWithLogo });
      } else {
        setQrCode({ preview: previewQrDataUrl, fullSize: fullSizeQrDataUrl });
      }

      qrData.count += 1;
      localStorage.setItem('qrData', JSON.stringify(qrData));
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputType === 'url' && !/^https?:\/\//i.test(inputValue)) {
      setInputValue(`https://${inputValue}`);
    }
    generateQRCode();
  };

  const handleInputTypeChange = (e) => {
    setInputType(e.target.value);
    setInputValue(''); // Clear the input value when the input type changes
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      if (file.size <= 1024 * 1024) { // 1MB limit
        const reader = new FileReader();
        reader.onload = (e) => setLogo(e.target.result);
        reader.readAsDataURL(file);
      } else {
        alert('File size should be less than 1MB');
      }
    } else {
      alert('Please upload a valid image file');
    }
  };

  const renderInputField = () => {
    switch (inputType) {
      case 'wifi':
        return (
          <>
            <input
              className="input"
              type="text"
              placeholder="SSID"
              value={inputValue.ssid || ''}
              onChange={(e) => setInputValue({ ...inputValue, ssid: e.target.value })}
              required
            />
            <input
              className="input"
              type="password"
              placeholder="Password"
              value={inputValue.password || ''}
              onChange={(e) => setInputValue({ ...inputValue, password: e.target.value })}
              required
            />
          </>
        );
      case 'sms':
        return (
          <>
            <input
              className="input"
              type="tel"
              placeholder="Phone Number"
              value={inputValue.number || ''}
              onChange={(e) => setInputValue({ ...inputValue, number: e.target.value })}
              required
            />
            <input
              className="input"
              type="text"
              placeholder="Message"
              value={inputValue.message || ''}
              onChange={(e) => setInputValue({ ...inputValue, message: e.target.value })}
              required
            />
          </>
        );
      case 'tel':
        return (
          <input
            className="input"
            type="tel"
            placeholder="Phone Number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
          />
        );
      default:
        return (
          <input
            className="input"
            type="text"
            placeholder={`Enter ${inputType}`}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
          />
        );
    }
  };

  return (
    <div className="container">
      <h1 className="title">Free QR Code Generator</h1>
      <div className="info-card">
        <p>Abosultely <strong>FREE</strong> to use! No Data Harvesting!</p>
        <p>Including <strong>SVGs</strong>!</p>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <select className="select" value={inputType} onChange={handleInputTypeChange}>
          <option value="url">URL</option>
          <option value="text">Text</option>
          <option value="wifi">Wi-Fi</option>
          <option value="tweet">Pre-populated Tweet</option>
          <option value="sms">SMS</option>
          <option value="tel">Phone Number</option>
          <option value="bitcoin">Bitcoin Address</option>
          <option value="monero">Monero Address</option>
        </select>
        {renderInputField()}
        <select className="select" value={fileType} onChange={(e) => setFileType(e.target.value)}>
          <option value="png">PNG</option>
          <option value="jpeg">JPG</option>
          <option value="svg">SVG</option>
        </select>
        <select className="select" value={size} onChange={(e) => setSize(parseInt(e.target.value))}>
          <option value="150">150 x 150 pixels</option>
          <option value="300">300 x 300 pixels</option>
          <option value="500">500 x 500 pixels</option>
          <option value="800">800 x 800 pixels</option>
          <option value="1000">1000 x 1000 pixels</option>
          <option value="1500">1500 x 1500 pixels</option>
        </select>
        <div className="logo-upload">
          <label htmlFor="logo-upload">
            <NewIcon className="new-icon" /> 
             Upload Logo (optional, max 1MB):
          </label>
          <input
            id="logo-upload"
            type="file"
            accept="image/*"
            onChange={handleLogoUpload}
          />
        </div>
        <button className="button" type="submit">Generate QR Code</button>
      </form>
      {qrCode && (
        <div className="qr-code-container">
          <img className="qr-code-image" src={qrCode.preview} alt="Generated QR Code" />
          <a className="download-link" href={qrCode.fullSize} download={`qr_code_${size}x${size}.${fileType}`}>
            Download QR Code ({size}x{size})
          </a>
        </div>
      )}
      <div className="footer-card">
        <h2>Thank you for using the QR Code Generator!</h2>
        <p>I appreciate your support!🚀 <a href="https://www.tech619.com" target="_blank" rel="noopener noreferrer">@0xberto</a></p>
        <a href="https://buymeacoffee.com/0xBerto" target="_blank" rel="noopener noreferrer" className="generate-site-button">
          <span className="button-content">☕️ Buy me a coffee</span>
        </a>
      </div>
    </div>
  );
};

export default QRCodeGenerator;